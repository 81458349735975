import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import collapseDownImage from '../../webComponents/assets/icons/collapse_down.svg';
import { getUserHasPermissionToView } from '../../lib/userFunctions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.backgroundWhite.main,
    height: '100%',
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
    minWidth: 165,
    overflowY: 'auto',
  },
  homeButton: {
    color: theme.palette.primary.main,
    height: 50,
    flex: '0 0 auto',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center',
  },
  button: {
    height: 36,
    flex: '0 0 auto',
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
    fontWeight: 500,
    color: theme.palette.textDarkGrey.main,
    fontSize: 14,
    paddingLeft: 25,
    paddingRight: 10,
    borderLeft: '5px solid transparent',
    justifyContent: 'flex-start',
  },
  activeButton: {
    backgroundColor: theme.palette.primaryLight.main,
    borderLeftColor: theme.palette.primary.main,
    borderBottomColor: 'transparent',
    color: theme.palette.primary.main,
  },
  mainMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    flex: '0 0 auto',
  },
  subMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    flex: '0 0 auto',
  },
  subMenuHeader: {
    backgroundColor: '#F5F5F5',
    borderTop: `1px solid ${theme.palette.borderGrey.main}`,
    borderBottom: `1px solid ${theme.palette.borderGrey.main}`,
    fontSize: 12,
    color: theme.palette.textDarkGrey.main,
    textTransform: 'uppercase',
    paddingLeft: 30,
    paddingRight: 6,
    height: 36,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subMenuIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform 0.3s',
  },
  subMenuIconOpen: {
    transform: 'rotate(90deg)',
  },
  subMenuLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'max-height 0.3s',
    overflow: 'hidden',
    maxHeight: 0,
    flex: '0 0 auto',
  },
  subMenuLinkContainerOpen: {
    maxHeight: 1000,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'flex-end',
    '& > a': {
      width: '100%',
      '&:first-child': {
        borderTop: `1px solid ${theme.palette.borderGrey.main}`,
      },
    },
  },
}));

//#region Menu items.
const RECEPTION = 'reception';
const WORK_QUEUE = 'workQueue';
const INSPECTION_HISTORY = 'inspectionHistory';
const SELF_MONITORING = 'surveillance';
const FAULT_REPORT = 'faultReport';
const HAYNES_DATA = 'haynesInfo';
const CUSTOMER_FEEDBACK = 'customerFeedback'
const ATJ_PRINTS = 'atjPrints';
const MARKETING = 'marketing';
const BOOSTER = 'booster';
const ATJ = 'atj';
const TRAFICOM_EXTRANET = 'traficomExtranet';
const BULLETIN = 'bulletin';
const HELP = 'help';
const MAIN_MENU_ITEMS = [
  RECEPTION,
  WORK_QUEUE,
  INSPECTION_HISTORY,
  SELF_MONITORING,
  FAULT_REPORT,
  ATJ_PRINTS,
  HAYNES_DATA,
  CUSTOMER_FEEDBACK,
  MARKETING,
  BOOSTER,
  ATJ,
  TRAFICOM_EXTRANET,
  BULLETIN,
  HELP,
];

// Location menu items.
const SALES_REPORTS = 'salesReports';
const SALES_INVOICE = 'salesInvoice';
const SALES_LEDGER = 'salesLedger';
const SALES_LEDGER_REPORTS = 'salesLedgerReports';
const CASH_BOOK = 'cashBook';
const SITE_PRODUCTS = 'siteProducts';
const SITE_CAMPAIGN = 'siteCampaign';
const SITE_CUSTOMERS = 'siteCustomers';
const DEVICES = 'devices';
const INSPECTION_LINES = 'inspectionLines';
const DEFAULT_LINES = 'defaultLines';
const SITE_COMMON_SETTINGS = 'siteCommonSettings';
const FINANCIAL_SETTINGS = 'financialSettings';
const REPORTS = 'reports';
const LOCATION_MENU_ITEMS = [
  SALES_REPORTS,
  SALES_INVOICE,
  SALES_LEDGER,
  SALES_LEDGER_REPORTS,
  CASH_BOOK,
  SITE_PRODUCTS,
  SITE_CAMPAIGN,
  SITE_CUSTOMERS,
  DEVICES,
  INSPECTION_LINES,
  DEFAULT_LINES,
  SITE_COMMON_SETTINGS,
  FINANCIAL_SETTINGS,
  REPORTS,
];

// Chain menu items.
const CHAIN = 'chain';
const POWER_BI_REPORTS = 'powerBiReports';
const CHAIN_PRODUCTS = 'chainProducts';
const CHAIN_CAMPAIGN = 'chainCampaign';
const CHAIN_CUSTOMERS = 'chainCustomers';
const SITES = 'sites';
// const SITE_GROUPS = 'siteGroups';
const USERS = 'users';
const VEHICLE_GROUPS = 'vehicleGroups';
const JOB_TYPES = 'jobTypes';
const CONDITION_INSPECTION_JOB_TYPES = 'conditionInspectionJobTypes';
const CHAIN_COMMON_SETTINGS = 'chainCommonSettings';
const CHAIN_SALES_REPORTS = "chainSalesReports";
const CHAIN_SALES_LEDGER_REPORTS = 'chainSalesLedgerReports';

const CHAIN_MENU_ITEMS = [
  CHAIN_SALES_REPORTS,
  CHAIN_SALES_LEDGER_REPORTS,
  POWER_BI_REPORTS,
  CHAIN_PRODUCTS,
  CHAIN_CAMPAIGN,
  CHAIN_CUSTOMERS,
  USERS,
  CHAIN,
  SITES,
  // SITE_GROUPS,
  JOB_TYPES,
  CONDITION_INSPECTION_JOB_TYPES,
  VEHICLE_GROUPS,
  CHAIN_COMMON_SETTINGS,
];

const CHAIN_MESSAGES = 'chainMessages';
const MANAGE_BULLETIN = 'manageBulletin';
const COPY_DATA = 'copyData';
const CHAIN_MANAGEMENT = 'chainManagement';
const BOTTOM_MENU_ITEMS = [
  { route: CHAIN_MESSAGES, superUserOnly: false, kapaPath: '/chain-messages/chain'},
  { route: MANAGE_BULLETIN, superUserOnly: true },
  { route: COPY_DATA, superUserOnly: true },
  { route: CHAIN_MANAGEMENT, superUserOnly: false },
];

// Items to external system (KAPA).
const KAPA_LINKS = {
  [RECEPTION]: '/reception',
  [SITE_PRODUCTS]: '/product-management',
  [SITE_CAMPAIGN]: '/campaign-management',
  [CHAIN_PRODUCTS]: '/product-management/chain',
  [CHAIN_CAMPAIGN]: '/campaign-management/chain',
  [CHAIN_CUSTOMERS]: '/customer-register/chain',
  [CUSTOMER_FEEDBACK]: '/customer-feedback',
  [SALES_REPORTS]: '/salesreport',
  [SALES_INVOICE]: '/salesinvoice',
  [SALES_LEDGER]: '/salesledger',
  [SALES_LEDGER_REPORTS]: '/salesledger/report',
  [CASH_BOOK]: '/cashbook',
  [SITE_CUSTOMERS]: '/customer-register',
  [SITE_COMMON_SETTINGS]: '/settings',
  [FINANCIAL_SETTINGS]: '/settings/finance',
  [CHAIN_COMMON_SETTINGS]: '/settings/chain',
  [BULLETIN]: '/bulletin',
  [HELP]: '/help',
  [MANAGE_BULLETIN]: '/admin/bulletin',
  [CHAIN_SALES_REPORTS]: '/salesreport/chain',
  [CHAIN_SALES_LEDGER_REPORTS]: '/salesledger/report/chain',
}

const EXTERNAL_LINKS = {
  [BOOSTER]: '/', // TODO: add URL to Booster login page.
  [MARKETING]: process.env.REACT_APP_MARKETING_TOOL_URL,
  [ATJ]: process.env.REACT_APP_ATJ_URL,
  [TRAFICOM_EXTRANET]: process.env.REACT_APP_TRAFICOM_EXTRANET_URL,
  [POWER_BI_REPORTS]: process.env.REACT_APP_POWERBI_EXTRANET_URL, // URL to Power BI login. 'app.powerbi.com', //
}
//#endregion

function KapaLink({ to, children }) {
  const classes = useStyles();
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  return (
    <ButtonBase
      className={classes.button}
      component='a'
      href={`${process.env.REACT_APP_KAPA_BASE_URL}${to}?c=${selectedChainId}&s=${selectedSiteId}`}
    >
      {children}
    </ButtonBase>
  );
}

function MenuButton({ to, children }) {
  const classes = useStyles();

  const kapaLink = KAPA_LINKS[to];
  const externalLink = EXTERNAL_LINKS[to];

  if (externalLink) {
    // Open link in new tab.
    const openInNewTab = to !== MARKETING;
    return (
      <ButtonBase
        className={classes.button}
        component='a'
        href={externalLink}
        target={openInNewTab ? '_blank' : null}
      >
        {children}
      </ButtonBase>
    );
  }
  if (kapaLink) {
    return (
      <KapaLink to={kapaLink}>
        {children}
      </KapaLink>
    );
  }

  return (
    <ButtonBase
      className={classes.button}
      activeClassName={classes.activeButton}
      component={NavLink}
      to={`/${to}`}
    >
      {children}
    </ButtonBase>
  );
}

function MainMenu({ items, user }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.mainMenuContainer}>
      <ButtonBase className={classes.homeButton}>DORIS</ButtonBase>
      {items.map(item => (
        getUserHasPermissionToView(user, item) && <MenuButton key={item} to={item}>
          {item === BOOSTER ? 'Booster' : t(item)}
        </MenuButton>
      ))}
    </div>
  );
}

function SubMenu({ title, items, user, site }) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const visibleItems = items.filter(item => getUserHasPermissionToView(user, item));

  useEffect(() => {
    const routeParts = location.pathname.split('/');
    const currentRoute = routeParts.length > 0 ? routeParts[1] : '';
    const currentRouteInMenu = items.some(item => item === currentRoute);
    if (currentRouteInMenu) {
      setIsOpen(true);
    }
  }, [location, items]);

  const isFeatureEnabled = (feature) => {
    // Site is needed.
    if (!site) {
      return false;
    }

    // Check for condition inspection job types.
    if (feature === CONDITION_INSPECTION_JOB_TYPES) {
      // Must be enabled on the site.
      return site.enableConditionInspection;
    }

    // Enabled by default.
    return true;
  }

  return (
    visibleItems.length ? <div className={classes.subMenuContainer}>
      <ButtonBase className={classes.subMenuHeader} onClick={() => setIsOpen(!isOpen)}>
        {t(title)} <img alt='' src={collapseDownImage} className={clsx(classes.subMenuIcon, { [classes.subMenuIconOpen]: isOpen })} />
      </ButtonBase>
      <div className={clsx(classes.subMenuLinkContainer, { [classes.subMenuLinkContainerOpen]: isOpen })}>
        {visibleItems.map(item => {
          // Check if the feature is enabled for site.
          if (isFeatureEnabled(item)) {
            return (
              <MenuButton
                key={item}
                to={item}
              >
                {t(item)}
              </MenuButton>
            );
          }

          // Not enabled, render nothing.
          return null;
          })}
      </div>
    </div> : null
  );
}

function BottomButtons({ items, user }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const userIsSuperUser = user && user.userType === 'superuser';
  const userIsAdmin = user && user.userType === 'admin';

  // Filter items by user type.
  const itemsToShow = userIsSuperUser ? items : (
    userIsAdmin ? items.filter(item => !item.superUserOnly) : items.filter(item => getUserHasPermissionToView(user, item.route))
  );

  // Handle empty items.
  if (!itemsToShow.length) {
    return null;
  }

  return (
    <div className={classes.bottomContainer}>
      {itemsToShow.map((item) => (
        item.kapaPath ? <KapaLink key={item.route} to={item.kapaPath}> {t(`permissions.${item.route}`)} </KapaLink> : <MenuButton key={item.route} to={item.route}>
          {t(item.route)}
        </MenuButton>
      ))}
    </div>
  );
}

function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const inspectionMatch = useRouteMatch('/inspection/:id');
  const user = useSelector(state => state.auth.user);
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);
  const selectedSite = useSelector(state => state.sites.sites.find(site => site.id === selectedSiteId));

  if (isMobileScreen || inspectionMatch) {
    return null;
  }
  return (
    <div className={classes.container}>
      <MainMenu items={MAIN_MENU_ITEMS} user={user} />
      <SubMenu title='site' items={LOCATION_MENU_ITEMS} user={user} site={selectedSite} />
      <SubMenu title='chain' items={CHAIN_MENU_ITEMS} user={user} site={selectedSite}/>
      <BottomButtons items={BOTTOM_MENU_ITEMS} user={user} />
    </div>
  )
}

export default NavBar;